import React from 'react';
import { IconProps } from './IconProps';


export const Pencil: React.FC<IconProps> = (props) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
            <path opacity="0.2"
                  d="M34.6344 14.1156L30 18.75L21.25 10L25.8844 5.36564C26.1188 5.1314 26.4366 4.99982 26.768 4.99982C27.0993 4.99982 27.4172 5.1314 27.6516 5.36564L34.6344 12.3438C34.7511 12.4599 34.8437 12.598 34.9068 12.75C34.97 12.902 35.0026 13.0651 35.0026 13.2297C35.0026 13.3943 34.97 13.5574 34.9068 13.7094C34.8437 13.8614 34.7511 13.9995 34.6344 14.1156Z"
                  fill="#343330"/>
            <path
                d="M35.5172 11.4641L28.536 4.48128C28.3038 4.24907 28.0282 4.06487 27.7248 3.9392C27.4215 3.81352 27.0963 3.74884 26.768 3.74884C26.4396 3.74884 26.1145 3.81352 25.8111 3.9392C25.5078 4.06487 25.2322 4.24907 25 4.48128L5.73282 23.75C5.49967 23.9813 5.31481 24.2567 5.18901 24.5601C5.06321 24.8634 4.99896 25.1888 5.00001 25.5172V32.5C5.00001 33.1631 5.2634 33.799 5.73225 34.2678C6.20109 34.7366 6.83697 35 7.50001 35H14.4828C14.8113 35.0011 15.1366 34.9368 15.44 34.811C15.7434 34.6852 16.0187 34.5004 16.25 34.2672L35.5172 15C35.7494 14.7679 35.9336 14.4922 36.0593 14.1889C36.185 13.8855 36.2496 13.5604 36.2496 13.2321C36.2496 12.9037 36.185 12.5786 36.0593 12.2752C35.9336 11.9719 35.7494 11.6962 35.5172 11.4641ZM8.0172 25L21.25 11.7672L23.8578 14.375L10.625 27.6063L8.0172 25ZM7.50001 28.0172L11.9828 32.5H7.50001V28.0172ZM15 31.9828L12.3922 29.375L25.625 16.1422L28.2328 18.75L15 31.9828ZM30 16.9828L23.0172 10L26.7672 6.25003L33.75 13.2313L30 16.9828Z"
                fill="#343330"/>
        </svg>
    )
};
